import React from 'react';
import { Route } from 'react-router-dom';
import { Home } from './components/Home';
import { Layout } from './components/Layout';

import AllAssets from './components/AllAssets';
import AssetSummary from './components/AssetSummary';
import AssetSummaryDrillDown from './components/AssetSummaryDrillDown';
import CRSEntry from './components/CRSEntry';
import StudyRoom from './components/StudyRoom';
import StudyStructure from './components/StudyStructure';
import StudySummary from './components/StudySummary';
import StudyUpload from './components/StudyUpload';
import './custom.css';
// Lookups
import AssetCodingClass from './components/AssetCodingClass';
import AssetGroup from './components/AssetGroup';
import AssetServiceLife from './components/AssetServiceLife';
import AssetType from './components/AssetType';
import RoomType from './components/RoomType';
import StructureLocationType from './components/StructureLocationType';
import UnitOfMeasure from './components/UnitOfMeasure';
import AnalyticsDashboard from './components/AnalyticsDashboard';
import ActivityLogsGrid from './components/ActivityLogsGrid';


import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faAngleDoubleLeft, faAngleDoubleRight, faBalanceScale, faChalkboard, faCheckSquare, faCloudShowersHeavy, faEdit, faInfoCircle, faSave, faSignal } from '@fortawesome/free-solid-svg-icons';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { InflationGrid } from './components/InflationGrid';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';

library.add(fab, faCheckSquare, faBalanceScale, faChalkboard, faSave, faSignal, faCloudShowersHeavy, faEdit, faAngleDoubleLeft, faAngleDoubleRight, faInfoCircle);

function App() {
    return (
        <Layout>
            <Route exact path='/' component={Home} />
            <Route path='/study-setup-summary' component={StudySummary} />
            <Route path='/asset-coding-class' component={AssetCodingClass} />
            <Route path='/asset-group' component={AssetGroup} />
            <Route path='/asset-service-life' component={AssetServiceLife} />
            <Route path='/asset-type' component={AssetType} />
            <Route path='/room-type' component={RoomType} />
            <Route path='/unit-of-measure' component={UnitOfMeasure} />
            <Route path='/study-structure' component={StudyStructure} />
            <Route path='/study-room' component={StudyRoom} />
            <Route path='/asset-summary' component={AssetSummary} />
            <Route path='/asset-summary-drill-down' component={AssetSummaryDrillDown} />
            <Route path='/all-assets' component={AllAssets} />
            <Route path='/dashboard' component={AnalyticsDashboard} />
            <Route path='/structure-location-type' component={StructureLocationType} />
            <Route path='/crs-entry' component={CRSEntry} />
            <Route path='/study-upload' component={StudyUpload} />
            <Route path='/inflation' component={InflationGrid} />
            <Route path='/activity-logs' component={ActivityLogsGrid} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        </Layout>
    );
}

export default App;